import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import Header from '../components/Header';
import Content from '../components/Content';
import { Button, Field, Icon } from 'views/components/General';
import { MigrationSelectors, MigrationOperations } from 'state/ducks/migration';
import styles from './style.module.scss';

class WorkOrderPriorities extends Component {
  updatePriority = (id, prio) => {
    this.props.updateConfig({
      work_order_priorities: {
        high: {
          $apply: ids => ids.filter(loopedId => loopedId !== id),
        },
        medium: {
          $apply: ids => ids.filter(loopedId => loopedId !== id),
        },
        low: {
          $apply: ids => ids.filter(loopedId => loopedId !== id),
        },
        [prio]: {
          $unshift: [id],
        },
      },
    });
  };

  clearPriority = id => {
    this.props.updateConfig({
      work_order_priorities: {
        high: {
          $apply: ids => ids.filter(loopedId => loopedId !== id),
        },
        medium: {
          $apply: ids => ids.filter(loopedId => loopedId !== id),
        },
        low: {
          $apply: ids => ids.filter(loopedId => loopedId !== id),
        },
      },
    });
  };

  getSelectedPriority = id => {
    const { high, medium, low } = this.props.configData.work_order_priorities;
    if (high.includes(id)) return 'high';
    if (medium.includes(id)) return 'medium';
    if (low.includes(id)) return 'low';
    return null;
  };

  getLang = prio => {
    if (prio == null) return 'Rensa prioritet';
    if (prio === 'high') return 'Hög prioritet';
    if (prio === 'medium') return 'Medel prioritet';
    if (prio === 'low') return 'Låg prioritet';
    return null;
  };

  getValue = prio => {
    if (prio == null) return <span className={styles['clear']}>{this.getLang(null)}</span>;
    return this.getLang(prio);
  };

  renderPriority = prio => {
    const selectedPrio = this.getSelectedPriority(prio.id);

    return (
      <div className={styles['row']}>
        <div className={styles['left-panel']}>{prio[this.props.amuData.amu_language]}</div>
        <div className={styles['arrow']}>
          <Icon solid type="arrow-alt-right" />
        </div>
        <div className={styles['right-panel']}>
          <Field.Dropdown clearable={false} value={this.getValue(selectedPrio)}>
            <Field.Dropdown.Item
              selected={selectedPrio == null}
              onClick={() => {
                this.clearPriority(prio.id);
              }}
            >
              {this.getLang(null)}
            </Field.Dropdown.Item>
            <Field.Dropdown.Separator />
            <Field.Dropdown.Item
              selected={selectedPrio === 'high'}
              onClick={() => {
                this.updatePriority(prio.id, 'high');
              }}
            >
              {this.getLang('high')}
            </Field.Dropdown.Item>
            <Field.Dropdown.Item
              selected={selectedPrio === 'medium'}
              onClick={() => {
                this.updatePriority(prio.id, 'medium');
              }}
            >
              {this.getLang('medium')}
            </Field.Dropdown.Item>
            <Field.Dropdown.Item
              selected={selectedPrio === 'low'}
              onClick={() => {
                this.updatePriority(prio.id, 'low');
              }}
            >
              {this.getLang('low')}
            </Field.Dropdown.Item>
          </Field.Dropdown>
        </div>
      </div>
    );
  };

  renderPriorities = () => {
    if (this.props.amuData.work_order_priorities.length === 0) {
      return <div className={styles['empty']}>Det finns inga prioriteter</div>;
    }

    return (
      <div className={styles['priorities']}>
        {this.props.amuData.work_order_priorities.map(this.renderPriority)}
      </div>
    );
  };

  render() {
    return (
      <>
        <Header
          title="Prioritet för arbetsordrar"
          subtitle="I Mainter har ni endast möjlighet att använda 3 förbestämda prioriteter på arbetsordrar. Låg, mellan och hög. Det innebär att ni måste bestämma hur dagens prioriteter ska klassas in i dessa 3 kategorier."
        />
        <Content width={420}>{this.renderPriorities()}</Content>
        <Button
          primary
          label="Nästa steg"
          onClick={() => {
            this.props.completeStep(this.props.step);
            this.props.history.push(`?step=${this.props.step + 1}`);
          }}
        />
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateConfig: MigrationOperations.updateConfig,
      completeStep: MigrationOperations.completeStep,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    configData: MigrationSelectors.configData(state),
    amuData: MigrationSelectors.amuData(state),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkOrderPriorities));
