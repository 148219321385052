import types from './types';

const fetchImportDataSuccess = payload => ({
  type: types.FETCH_IMPORT_DATA_SUCCESS,
  payload,
});

const fetchImportDataFailed = () => ({
  type: types.FETCH_IMPORT_DATA_FAILED,
});

const submitSuccess = () => ({
  type: types.SUBMIT_SUCCESS,
});

const updateConfig = payload => ({
  type: types.UPDATE_CONFIG,
  payload: payload,
});

const completeStep = payload => ({
  type: types.COMPLETE_STEP,
  payload: payload,
});

export default {
  fetchImportDataSuccess,
  fetchImportDataFailed,
  submitSuccess,
  updateConfig,
  completeStep,
};
