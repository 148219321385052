import React from 'react';
import { Link } from 'react-router-dom';
import styles from './style.module.scss';
import { UpgradeToProIcon, Icon } from 'views/components/General';

const Container = ({ children }) => <div className={styles['tabs']}>{children}</div>;
const NewTab = ({
  children,
  selected,
  onClick,
  iconColor,
  iconThickness,
  locked = false,
  small = false,
  icon = null,
  linkTo = null,
}) => {
  const renderChildren = () => {
    return (
      <>
        {icon != null ? (
          <div className={styles['icon-container']}>
            <Icon
              size={16}
              type={icon}
              regular={iconThickness === 'regular'}
              light={iconThickness === 'light'}
              green={iconColor === 'green'}
              red={iconColor === 'red'}
              orange={iconColor === 'orange'}
              blue={iconColor === 'blue'}
              white={iconColor === 'white'}
            />
          </div>
        ) : null}
        <div>{children}</div>
      </>
    );
  };

  if (linkTo) {
    return (
      <Link
        className={`${styles['tab']} ${selected ? styles['selected'] : ''} ${small ? styles['small'] : ''}`}
        to={linkTo}
      >
        {renderChildren()}
        {locked === true ? (
          <div className={styles['lock-container']}>
            <UpgradeToProIcon />
          </div>
        ) : null}
      </Link>
    );
  }
  return (
    <div
      className={`${styles['tab']} ${selected ? styles['selected'] : ''} ${small ? styles['small'] : ''}`}
      onClick={onClick}
    >
      {renderChildren()}
    </div>
  );
};

NewTab.Container = Container;
export default NewTab;
