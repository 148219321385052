import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import Header from '../components/Header';
import Content from '../components/Content';
import { Button, Field, Icon } from 'views/components/General';
import { MigrationSelectors, MigrationOperations } from 'state/ducks/migration';
import styles from './style.module.scss';

class WorkOrderCategories extends Component {
  updateCategory = (id, category) => {
    this.props.updateConfig({
      work_order_categories: {
        preventive: {
          $apply: ids => ids.filter(loopedId => loopedId !== id),
        },
        preventive_condition_based: {
          $apply: ids => ids.filter(loopedId => loopedId !== id),
        },
        preventive_predetermined: {
          $apply: ids => ids.filter(loopedId => loopedId !== id),
        },
        corrective: {
          $apply: ids => ids.filter(loopedId => loopedId !== id),
        },
        corrective_defered: {
          $apply: ids => ids.filter(loopedId => loopedId !== id),
        },
        corrective_immediate: {
          $apply: ids => ids.filter(loopedId => loopedId !== id),
        },
        improvement: {
          $apply: ids => ids.filter(loopedId => loopedId !== id),
        },
        modification: {
          $apply: ids => ids.filter(loopedId => loopedId !== id),
        },
        [category]: {
          $unshift: [id],
        },
      },
    });
  };

  clearCategory = id => {
    this.props.updateConfig({
      work_order_categories: {
        preventive: {
          $apply: ids => ids.filter(loopedId => loopedId !== id),
        },
        preventive_condition_based: {
          $apply: ids => ids.filter(loopedId => loopedId !== id),
        },
        preventive_predetermined: {
          $apply: ids => ids.filter(loopedId => loopedId !== id),
        },
        corrective: {
          $apply: ids => ids.filter(loopedId => loopedId !== id),
        },
        corrective_defered: {
          $apply: ids => ids.filter(loopedId => loopedId !== id),
        },
        corrective_immediate: {
          $apply: ids => ids.filter(loopedId => loopedId !== id),
        },
        improvement: {
          $apply: ids => ids.filter(loopedId => loopedId !== id),
        },
        modification: {
          $apply: ids => ids.filter(loopedId => loopedId !== id),
        },
      },
    });
  };

  getSelectedCategory = id => {
    const {
      preventive,
      preventive_condition_based,
      preventive_predetermined,
      corrective,
      corrective_defered,
      corrective_immediate,
      improvement,
      modification,
    } = this.props.configData.work_order_categories;

    if (preventive.includes(id)) return 'preventive';
    if (preventive_condition_based.includes(id)) return 'preventive_condition_based';
    if (preventive_predetermined.includes(id)) return 'preventive_predetermined';
    if (corrective.includes(id)) return 'corrective';
    if (corrective_defered.includes(id)) return 'corrective_defered';
    if (corrective_immediate.includes(id)) return 'corrective_immediate';
    if (improvement.includes(id)) return 'improvement';
    if (modification.includes(id)) return 'modification';
    return null;
  };

  getLang = category => {
    if (category == null) return 'Rensa kategori';
    if (category === 'preventive') return 'Förebyggande underhåll';
    if (category === 'preventive_condition_based') return 'Tillståndsbaserat';
    if (category === 'preventive_predetermined') return 'Förutbestämt';
    if (category === 'corrective') return 'Avhjälpande underhåll';
    if (category === 'corrective_defered') return 'Uppskjutet';
    if (category === 'corrective_immediate') return 'Akut';
    if (category === 'improvement') return 'Förbättring';
    if (category === 'modification') return 'Modifiering';
    return null;
  };

  getValue = category => {
    if (category == null) return <span className={styles['clear']}>{this.getLang(null)}</span>;
    return this.getLang(category);
  };

  renderCategory = category => {
    const selectedCategory = this.getSelectedCategory(category.id);

    return (
      <div className={styles['row']}>
        <div className={styles['left-panel']}>{category[this.props.amuData.amu_language]}</div>
        <div className={styles['arrow']}>
          <Icon solid type="arrow-alt-right" />
        </div>
        <div className={styles['right-panel']}>
          <Field.Dropdown clearable={false} value={this.getValue(selectedCategory)}>
            <Field.Dropdown.Item
              selected={selectedCategory == null}
              onClick={() => {
                this.clearCategory(category.id);
              }}
            >
              {this.getLang(null)}
            </Field.Dropdown.Item>
            <Field.Dropdown.Separator />
            <Field.Dropdown.Item
              selected={selectedCategory === 'preventive'}
              onClick={() => {
                this.updateCategory(category.id, 'preventive');
              }}
            >
              {this.getLang('preventive')}
            </Field.Dropdown.Item>
            <Field.Dropdown.Item
              selected={selectedCategory === 'preventive_predetermined'}
              onClick={() => {
                this.updateCategory(category.id, 'preventive_predetermined');
              }}
            >
              <span className={styles['indent']}>{this.getLang('preventive_predetermined')}</span>
            </Field.Dropdown.Item>
            <Field.Dropdown.Item
              selected={selectedCategory === 'preventive_condition_based'}
              onClick={() => {
                this.updateCategory(category.id, 'preventive_condition_based');
              }}
            >
              <span className={styles['indent']}>{this.getLang('preventive_condition_based')}</span>
            </Field.Dropdown.Item>
            <Field.Dropdown.Item
              selected={selectedCategory === 'corrective'}
              onClick={() => {
                this.updateCategory(category.id, 'corrective');
              }}
            >
              {this.getLang('corrective')}
            </Field.Dropdown.Item>
            <Field.Dropdown.Item
              selected={selectedCategory === 'corrective_immediate'}
              onClick={() => {
                this.updateCategory(category.id, 'corrective_immediate');
              }}
            >
              <span className={styles['indent']}>{this.getLang('corrective_immediate')}</span>
            </Field.Dropdown.Item>
            <Field.Dropdown.Item
              selected={selectedCategory === 'corrective_defered'}
              onClick={() => {
                this.updateCategory(category.id, 'corrective_defered');
              }}
            >
              <span className={styles['indent']}>{this.getLang('corrective_defered')}</span>
            </Field.Dropdown.Item>
            <Field.Dropdown.Item
              selected={selectedCategory === 'improvement'}
              onClick={() => {
                this.updateCategory(category.id, 'improvement');
              }}
            >
              {this.getLang('improvement')}
            </Field.Dropdown.Item>
            <Field.Dropdown.Item
              selected={selectedCategory === 'modification'}
              onClick={() => {
                this.updateCategory(category.id, 'modification');
              }}
            >
              {this.getLang('modification')}
            </Field.Dropdown.Item>
          </Field.Dropdown>
        </div>
      </div>
    );
  };

  renderCategories = () => {
    if (this.props.amuData.work_order_categories.length === 0) {
      return <div className={styles['empty']}>Det finns inga kategorier</div>;
    }

    return (
      <div>
        {this.props.amuData.work_order_categories.map(this.renderCategory)}
      </div>
    );
  };

  render() {
    return (
      <>
        <Header
          title="Kategori för arbetsordrar"
          subtitle={"I Mainter har ni möjlighet att klassa arbetsordrar efter den svenska standarden för kategori förebyggande/avhjälpande. Konfigurera hur ni önskar kategoriesera ert tidigare fält \"Typ\" till Mainter."}
        />
        <Content width={420}>{this.renderCategories()}</Content>
        <Button
          primary
          label="Nästa steg"
          onClick={() => {
            this.props.completeStep(this.props.step);
            this.props.history.push(`?step=${this.props.step + 1}`);
          }}
        />
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateConfig: MigrationOperations.updateConfig,
      completeStep: MigrationOperations.completeStep,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    configData: MigrationSelectors.configData(state),
    amuData: MigrationSelectors.amuData(state),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkOrderCategories));
