import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import Header from '../components/Header';
import Content from '../components/Content';
import { Button, Field } from 'views/components/General';
import { MigrationSelectors, MigrationOperations } from 'state/ducks/migration';
import styles from './style.module.scss';

class Language extends Component {
  renderSelectedLang = () => {
    switch (this.props.configData.new_lang) {
      case 'sv':
        return 'Svenska';
      case 'en':
        return 'Engelska';
      default:
        return '';
    }
  };

  renderField = () => (
    <Field.Dropdown clearable={false} value={this.renderSelectedLang()}>
      <Field.Dropdown.Item
        selected={this.props.configData.new_lang === 'sv'}
        onClick={() => {
          this.props.updateConfig({
            new_lang: {
              $set: 'sv',
            },
          });
        }}
      >
        Svenska
      </Field.Dropdown.Item>
      <Field.Dropdown.Item
        selected={this.props.configData.new_lang === 'en'}
        onClick={() => {
          this.props.updateConfig({
            new_lang: {
              $set: 'en',
            },
          });
        }}
      >
        Engelska
      </Field.Dropdown.Item>
    </Field.Dropdown>
  );

  render() {
    return (
      <>
        <Header
          title="Språk"
          subtitle={
            <span className={styles['subtitle']}>
              Bestäm vilket språk som ska migreras till Mainter.
            </span>
          }
        />
        <Content width={420}>{this.renderField()}</Content>
        <Button
          primary
          label="Nästa steg"
          onClick={() => {
            this.props.completeStep(this.props.step);
            this.props.history.push(`?step=${this.props.step + 1}`);
          }}
        />
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateConfig: MigrationOperations.updateConfig,
      completeStep: MigrationOperations.completeStep,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    configData: MigrationSelectors.configData(state),
    amuData: MigrationSelectors.amuData(state),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Language));
