import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import Header from '../components/Header';
import Content from '../components/Content';
import { Button, Field } from 'views/components/General';
import { MigrationSelectors, MigrationOperations } from 'state/ducks/migration';
import styles from './style.module.scss';

class ArchivedAssets extends Component {
  state = {
    number: '',
  };

  renderField = () => (
    <>
      <Field.Radio
        label="Behåll planerade jobb"
        checked={this.props.configData.ignore_work_orders_for_inactive_asset === false}
        onChange={() =>
          this.props.updateConfig({
            ignore_work_orders_for_inactive_asset: {
              $set: false,
            },
          })
        }
      />
      <Field.Radio
        label="Radera planerade jobb"
        checked={this.props.configData.ignore_work_orders_for_inactive_asset === true}
        onChange={() =>
          this.props.updateConfig({
            ignore_work_orders_for_inactive_asset: {
              $set: true,
            },
          })
        }
      />
    </>
  );

  render() {
    return (
      <>
        <Header
          title="Arbetsordrar för vilande objekt"
          subtitle="Bestämmer vad du vill göra med planerade arbetsordrar för vilande objekt. Väljer du att behålla dem kommer de visas i listan över samtliga planerade arbetsordrar."
        />
        <Content width={420}>{this.renderField()}</Content>
        <Button
          primary
          label="Nästa steg"
          onClick={() => {
            this.props.completeStep(this.props.step);
            this.props.history.push(`?step=${this.props.step + 1}`);
          }}
        />
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateConfig: MigrationOperations.updateConfig,
      completeStep: MigrationOperations.completeStep,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    configData: MigrationSelectors.configData(state),
    amuData: MigrationSelectors.amuData(state),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ArchivedAssets));
