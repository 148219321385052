import React, { Component } from 'react';
import styles from './style.module.scss';

export default class Content extends Component {
  render() {
    let style = {};
    if (this.props.width) {
      style = { ...style, width: this.props.width };
    }
    return (
      <div style={style} className={styles['content']}>
        {this.props.children}
      </div>
    );
  }
}
