import React from 'react';
import { Icon } from 'views/components/General';
import PropTypes from 'prop-types';
import styles from './style.module.scss';

const WarningMessage = ({ type, icon, children, hideIcon }) => {
  return (
    <div
      className={`${styles['info']} ${hideIcon ? styles['hide-icon'] : ''} ${
        type === 'warning' ? styles['warning'] : styles['destructive']
      }`}
    >
      {hideIcon ? null : <Icon type={icon} />}
      <p>{children}</p>
    </div>
  );
};

export default WarningMessage;

WarningMessage.propTypes = {
  type: PropTypes.oneOf(['destructive', 'warning']),
  icon: PropTypes.string.isRequired,
  children: PropTypes.node,
  hideIcon: PropTypes.bool,
};

WarningMessage.defaultProps = {
  type: 'warning',
  icon: 'warning sign',
  hideIcon: false,
};
