import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as moment from 'moment';
import { InlineModal } from 'views/components/General';
import styles from './style.module.scss';

const hours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
const minutes = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55];

export default class TimePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showTimePicker: false,
      showHours: true,
      time: this.isTimeStringValid(props.time) ? props.time : null,
    };
  }

  isTimeStringValid = dateString => moment(dateString, 'HH:mm').isValid();

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.time !== this.props.time && this.isTimeStringValid(this.props.time)) {
      this.setState({
        time: this.props.time,
      });
    }
  }

  open = () => {
    this.props.onOpen();
    this.setState({ showTimePicker: true });
  };

  close = () => {
    this.props.onClose();
    this.setState({ showTimePicker: false });
  };

  renderFixedTimePicker = () => (
    <>
      <div
        ref={ref => (this.inlineModalPositioningRef = ref)}
        onClick={() => {
          if (this.state.showTimePicker) {
            this.close();
          } else {
            this.open();
          }
        }}
      >
        {this.props.trigger}
      </div>
      <InlineModal
        positionToRef={this.inlineModalPositioningRef}
        open={this.state.showTimePicker}
        onClose={this.close}
      >
        <InlineModal.Body width={380} paddingTop>
          {this.renderTime()}
        </InlineModal.Body>
      </InlineModal>
    </>
  );

  renderTime = () => (
    <React.Fragment>
      {this.state.showHours ? (
        <div className={styles['hours']}>
          {hours.map(hour => {
            return (
              <div
                key={hour}
                onClick={() => {
                  const time = moment(this.state.time, 'HH:mm').isValid()
                    ? moment(this.state.time, 'HH:mm').set({ hour })
                    : moment(hour, 'HH:mm').set({ hour });
                  this.setState({
                    showHours: false,
                    time,
                  });
                }}
                className={styles['hour']}
              >
                {moment()
                  .set({
                    hour: hour,
                    minute:
                      this.isTimeStringValid(this.state.time) &&
                      moment(this.state.time, 'HH:mm').hour() === hour
                        ? moment(this.state.time, 'HH:mm').minute()
                        : 0,
                  })
                  .format('H:mm')}
              </div>
            );
          })}
        </div>
      ) : (
        <div className={styles['minutes']}>
          {minutes.map(minute => {
            return (
              <div
                key={minute}
                className={styles['minute']}
                onClick={() => {
                  const time = moment(this.state.time, 'HH:mm').set({ minute }).format('HH:mm');
                  this.setState(
                    {
                      showHours: true,
                      showTimePicker: false,
                      time,
                    },
                    () => {
                      this.props.onSelectMinute(
                        moment(this.state.time, 'HH:mm').set({ minute }).format('HH:mm')
                      );
                    }
                  );
                }}
              >
                {moment()
                  .set({ hour: moment(this.state.time, 'HH:mm').hour(), minute })
                  .format('H:mm')}
              </div>
            );
          })}
        </div>
      )}
    </React.Fragment>
  );

  renderInlineTimePicker = () => {
    return this.renderTime();
  };

  render() {
    return this.props.inline ? this.renderInlineTimePicker() : this.renderFixedTimePicker();
  }
}

TimePicker.propTypes = {
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  date: PropTypes.string, //YYYY-MM-DD
};

TimePicker.defaultProps = {
  onOpen: () => {},
  onClose: () => {},
  date: null,
};
