import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from '../../';
import { Icon } from 'views/components/General';
import { FormattedMessage } from 'react-intl';
import styles from './style.module.scss';

export default class Header extends Component {
  getSelectedHeaderComponentStyles = () => {
    const { tabs, selectedResourceComponent } = this.props;

    let lineClassName = '';
    if (tabs.length === 0 && selectedResourceComponent != null) {
      lineClassName = styles['selected-container-border'];
    }

    return `${styles['selected-container']} ${lineClassName}`;
  };

  renderBackButton = () => {
    if (this.props.showBackButton) {
      return (
        <div className={styles['back-container']}>
          <Button type="icon" icon={<Icon light type="angle-left" />} onClick={this.props.onBack} />
        </div>
      );
    }
    return null;
  };

  renderTitle = () => (
    <div className={styles['title-container']}>
      <div>{this.props.title}</div>
      {this.props.subtitle ? <div className={styles['subtitle']}>{this.props.subtitle}</div> : null}
    </div>
  );

  renderMenuButton = () => {
    return this.props.menuButtonComponent == null ? null : this.props.menuButtonComponent;
  };

  renderCloseButton = () => {
    if (this.props.hideCloseButton) return null;
    return (
      <div className={styles['close-container']}>
        <Button type="icon" icon={<Icon light size={16} type="times" />} onClick={this.props.onClose} />
      </div>
    );
  };

  renderHeaderContent = () => {
    return (
      <div className={`${styles['header']} ${this.props.tabs.length > 0 ? styles['has-tabs'] : ''}`}>
        {this.renderBackButton()}
        {this.renderTitle()}
        {this.renderMenuButton()}
        {this.renderCloseButton()}
      </div>
    );
  };

  renderSelectedResourceComponent = () => {
    if (this.props.selectedResourceComponent == null) return null;
    return (
      <div className={this.getSelectedHeaderComponentStyles()}>{this.props.selectedResourceComponent}</div>
    );
  };

  renderTabs = () => {
    if (this.props.tabs.length === 0) return null;
    return (
      <div className={styles['tab-container']}>
        {this.props.tabs.map(tab => (
          <div
            key={tab.id}
            className={`${styles['tab']} ${tab.id === this.props.selectedTab ? styles['selected'] : ''}`}
            onClick={e => this.props.onSelectTab(tab.id)}
          >
            <FormattedMessage id={tab.title} />
          </div>
        ))}
      </div>
    );
  };

  render() {
    return (
      <div className={styles['header-container']} style={{ width: this.props.width }}>
        {this.renderHeaderContent()}
        {this.renderSelectedResourceComponent()}
        {this.renderTabs()}
      </div>
    );
  }
}

Header.propTypes = {
  title: PropTypes.element,
  width: PropTypes.number,
  hideCloseButton: PropTypes.bool,
  showBackButton: PropTypes.bool,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    })
  ),
  onSelectTab: PropTypes.func,
  selectedResourceComponent: PropTypes.element,
  menuButtonComponent: PropTypes.element,
};

Header.defaultProps = {
  tabs: [],
  hideCloseButton: false,
  showBackButton: false,
  selectedResourceComponent: null,
  menuButtonComponent: null,
};
