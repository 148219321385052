import React from 'react';
import { Loader } from 'views/components/General';
import styles from './style.module.scss';

export default ({ title, destructive, saving, onClick, disabled, primary }) => {
  return (
    <div
      className={`${styles['button']} ${destructive ? styles['destructive'] : ''} ${
        disabled ? styles['disabled'] : ''
      } ${primary ? styles['primary'] : ''}`}
      onClick={e => (disabled || saving ? null : onClick())}
    >
      {saving ? (
        <React.Fragment>
          <Loader tiny />
          {title}
        </React.Fragment>
      ) : (
        title
      )}
    </div>
  );
};
