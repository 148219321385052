import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import Header from '../components/Header';
import { Button } from 'views/components/General';
import { MigrationSelectors, MigrationOperations } from 'state/ducks/migration';

class Submit extends Component {
  state = {
    loading: false,
  };

  validateConfigData = () => {
    const { amuData, configData } = this.props;

    const admin_user = amuData.users.find(user => user.id === configData.admin_id);
    if (admin_user && [null, ''].includes(admin_user.email) && configData.admin_override_email === '') {
      alert('Steg 2: Den valda användaren kräver en e-postadress');
      return false;
    }

    return true;
  };

  submit = () => {
    if (!this.validateConfigData()) return;

    const token = this.props.match.params.token;
    const configData = this.props.configData;
    this.setState({ loading: true });
    this.props.submitConfig(token, configData).then(() => {
      this.setState({ loading: false });
    });
  };

  render() {
    return (
      <>
        <Header
          title="Skicka in formulär"
          subtitle="Formuläret är nu färdigställt och vi kan påbörja migreringen så fort du skickar in på knappen nedan. Migreringen tar normalt endast 1-2 timmar, men kan ta upp till 48 timmar."
        />
        <Button primary loading={this.state.loading} label="Skicka in formulär" onClick={this.submit} />
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      submitConfig: MigrationOperations.submitConfig,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    configData: MigrationSelectors.configData(state),
    amuData: MigrationSelectors.amuData(state),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Submit));
