import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import Header from '../components/Header';
import Content from '../components/Content';
import { Button, Field } from 'views/components/General';
import { MigrationSelectors, MigrationOperations } from 'state/ducks/migration';
import styles from './style.module.scss';

class AssetNumber extends Component {
  state = {
    number: '',
  };

  renderField = () => (
    <>
      <Field.Number
        value={this.state.number}
        onChange={number => this.setState({ number })}
        onBlur={number => {
          this.props.updateConfig({
            next_asset_number: {
              $set: number,
            },
          });
        }}
      />
      <p className={styles['subtitle']}>Kan vara tomt</p>
    </>
  );

  render() {
    return (
      <>
        <Header
          title="Löpnummer för objekt"
          subtitle={`Bestämmer vilket som ska vara nästa löpnummer från objekt. Ni hittar värden Nästa löpnr under "Inställningar -> Objekt" i AM Underhåll.`}
        />
        <Content width={420}>{this.renderField()}</Content>
        <Button
          primary
          label="Nästa steg"
          onClick={() => {
            this.props.completeStep(this.props.step);
            this.props.history.push(`?step=${this.props.step + 1}`);
          }}
        />
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateConfig: MigrationOperations.updateConfig,
      completeStep: MigrationOperations.completeStep,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    configData: MigrationSelectors.configData(state),
    amuData: MigrationSelectors.amuData(state),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AssetNumber));
