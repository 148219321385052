import React from "react";
import PropTypes from "prop-types";
import Group from "./Group";
import { Icon, Loader } from "views/components/General";
import styles from "./style.module.scss";

export const ButtonType = {
  Text: "text",
  Icon: "icon",
  Default: "default",
};

const Button = (props) => {
  const clickButton = (e) => {
    if (props.disabled || props.loading) return;
    props.onClick(e);
  };

  const renderLabel = () => {
    if (props.icon) {
      return props.icon;
    } else if (props.translate) {
      return props.label;
    } else {
      return props.label;
    }
  };

  const renderCaret = () => {
    if (!props.caret) return null;
    return (
      <span className={styles["caret-container"]}>
        <Icon size={12} solid type="caret-down" />
      </span>
    );
  };

  const renderTextButton = () => {
    let classNames = [styles["text-button"]];
    if (props.primary) classNames = [...classNames, styles["primary"]];
    if (props.destructive) classNames = [...classNames, styles["destructive"]];
    if (props.noUnderline) {
      classNames = [...classNames, styles["no-underline"]];
    }
    return (
      <p
        className={classNames.join(" ")}
        style={{ fontSize: `${props.fontSize}px` }}
        onClick={clickButton}
      >
        {props.loading ? (
          <React.Fragment>
            <Loader tiny />
            {renderLabel()}
          </React.Fragment>
        ) : (
          renderLabel()
        )}
      </p>
    );
  };

  const renderIconButton = () => {
    const style = {
      width: props.iconButtonSize,
      height: props.iconButtonSize,
      maxHeight: props.iconButtonSize,
      maxWidth: props.iconButtonSize,
      minHeight: props.iconButtonSize,
      minWidth: props.iconButtonSize,
      borderRadius: props.iconButtonSize / 2,
    };
    let classNames = [styles["icon-button"]];
    if (props.lightHover) classNames = [...classNames, styles["light-hover"]];
    return (
      <div style={style} className={classNames.join(" ")} onClick={clickButton}>
        {props.loading ? (
          <React.Fragment>
            <Loader tiny />
          </React.Fragment>
        ) : (
          props.icon
        )}
      </div>
    );
  };

  const renderDefaultButton = () => {
    let classNames = [styles["button"]];
    if (props.small) classNames = [...classNames, styles["small"]];
    if (props.large) classNames = [...classNames, styles["large"]];
    if (props.fullWidth) classNames = [...classNames, styles["full-width"]];
    if (props.disabled) classNames = [...classNames, styles["disabled"]];
    if (props.primary) classNames = [...classNames, styles["primary"]];
    if (props.gray) classNames = [...classNames, styles["gray"]];
    if (props.destructive) classNames = [...classNames, styles["destructive"]];
    if (props.icon) classNames = [...classNames, styles["icon"]];
    if (props.active) classNames = [...classNames, styles["active"]];
    if (props.upgradeToPro) classNames = [...classNames, styles["upgrade"]];

    return (
      <button
        disabled={props.disabled}
        className={classNames.join(" ")}
        onClick={clickButton}
      >
        <div className={styles["loader-container"]}>
          <div className={styles["loader"]}>
            {props.loading ? <Loader light={!props.gray} tiny /> : null}
          </div>
        </div>
        <span className={props.loading ? styles["hide-label"] : ""}>
          {renderLabel()}
          {renderCaret()}
        </span>
      </button>
    );
  };

  const renderUpgradeToProButton = () => (
    <a href="https://amservice.com" target="_blank">
      {renderDefaultButton()}
    </a>
  );

  if (props.upgradeToPro) return renderUpgradeToProButton();
  if (props.type === ButtonType.Text) return renderTextButton();
  if (props.type === ButtonType.Icon) return renderIconButton();
  if (props.type === ButtonType.Default) return renderDefaultButton();
};

Button.Group = Group;
export default Button;

Button.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  translate: PropTypes.bool,
  primary: PropTypes.bool,
  gray: PropTypes.bool,
  destructive: PropTypes.bool,
  upgradeToPro: PropTypes.bool,
  caret: PropTypes.bool,
  onClick: PropTypes.func,
  icon: PropTypes.element,
  lightHover: PropTypes.bool,
  iconButtonSize: PropTypes.number,
  active: PropTypes.bool,
  fontSize: PropTypes.number,
  loading: PropTypes.bool.isRequired,
  noUnderline: PropTypes.bool,
  type: PropTypes.oneOf([ButtonType.Text, ButtonType.Default, ButtonType.Icon]),
};

Button.defaultProps = {
  loading: false,
  translate: true,
  primary: false,
  destructive: false,
  lightHover: false,
  active: false,
  caret: false,
  noUnderline: false,
  upgradeToPro: false,
  onClick: () => null,
  iconButtonSize: 32,
  fontSize: 13,
  gray: false,
  type: ButtonType.Default,
};
