import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import AnimateHeight from 'react-animate-height';
import { Icon } from 'views/components/General';
import styles from './style.module.scss';
import Column from './Column';
import IconColumn from './IconColumn';
import TitleColumn from './TitleColumn';
import ImageColumn from './ImageColumn';

class Item extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      new: props.new === true,
      hasBeenShownAsNew: props.new === true,
    };
  }

  componentDidMount() {
    if (this.state.new) {
      setTimeout(() => {
        this.setState({ new: false });
      }, 2000);
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.new && this.props.new && !this.state.hasBeenShownAsNew) {
      this.setState({ new: true, hasBeenShownAsNew: true }, () => {
        setTimeout(() => {
          this.setState({ new: false });
        }, 1500);
      });
    }
  }

  getPaddingLeft = () => {
    if (this.props.expandable) return 0;
    let defaultPadding = this.props.light ? 20 : 30;
    return this.props.paddingLeft || defaultPadding;
  };

  getPaddingRight = () => {
    let defaultPadding = this.props.light ? 20 : 30;
    return this.props.paddingRight || defaultPadding;
  };

  renderExpandableButton = () => {
    if (!this.props.expandable) return;

    let classNames = [styles['expandable-button']];
    if (this.state.expanded) classNames = [...classNames, styles['expanded']];
    if (this.props.loading) classNames = [...classNames, styles['loading']];

    const width = this.props.paddingLeft ? this.props.paddingLeft : 50;
    const style = {
      width,
      minWidth: width,
      maxWidth: width,
    };

    return (
      <div
        style={style}
        className={classNames.join(' ')}
        onClick={e => {
          if (this.props.loading) return null;
          e.stopPropagation();
          e.preventDefault();
          this.setState(prevState => ({
            expanded: !prevState.expanded,
          }));
        }}
      >
        <div className={styles['hover-container']}>
          <Icon size={24} light type="angle-right" />
        </div>
      </div>
    );
  };

  renderExpandedComponent = () => {
    if (this.props.expandable === false) return null;

    let style = {
      paddingRight: this.getPaddingRight(),
    };

    if (this.props.paddingLeft !== undefined) {
      style = {
        ...style,
        paddingLeft: this.props.paddingLeft,
      };
    } else if (this.props.expandable) {
      style = {
        ...style,
        paddingLeft: 50,
      };
    } else {
      style = {
        ...style,
        paddingLeft: 30,
      };
    }

    return (
      <AnimateHeight duration={250} height={this.state.expanded ? 'auto' : 0}>
        <div className={styles['expanded-container']} style={style}>
          {this.props.expandedComponent}
        </div>
      </AnimateHeight>
    );
  };

  renderIconContent = () => {
    if (this.props.icon == null) return null;
    return <div className={styles['icon-container']}>{this.props.icon}</div>;
  };

  renderButtonsContent = () => {
    if (this.props.iconButtons) {
      return (
        <div className={styles['buttons-container']} onClick={e => e.stopPropagation()}>
          {this.props.iconButtons}
        </div>
      );
    }
    return null;
  };

  renderDisclosureIndiciatorContent = () => {
    if (!this.props.disclosureIndicator) return null;

    const style = {
      marginRight: -8,
      marginLeft: 22,
    };

    return (
      <div style={style} className={styles['disclosure-indicator-container']}>
        <Icon light type="angle-right" />
      </div>
    );
  };

  renderContent = () => {
    let classNames = [styles['content']];
    if (this.props.clickable || this.props.linkTo) classNames = [...classNames, styles['clickable']];
    if (this.props.loading) classNames = [...classNames, styles['loading']];
    if (this.props.small) classNames = [...classNames, styles['small']];

    const style = {
      paddingLeft: this.getPaddingLeft(),
      paddingRight: this.getPaddingRight(),
    };

    const content = (
      <div
        className={classNames.join(' ')}
        style={style}
        onClick={() => (this.props.clickable ? this.props.onClick() : null)}
      >
        {this.renderExpandableButton()}
        <div className={styles['columns']}>{this.props.children}</div>
        {this.renderButtonsContent()}
        {this.renderDisclosureIndiciatorContent()}
      </div>
    );

    if (this.props.linkTo) {
      return (
        <Link className={styles['link']} to={this.props.linkTo}>
          {content}
        </Link>
      );
    }
    return content;
  };

  render() {
    let classNames = [styles['item']];
    if (this.state.new) classNames = [...classNames, styles['new']];
    if (this.props.expandable) classNames = [...classNames, styles['expandable']];
    if (this.props.disabled) classNames = [...classNames, styles['disabled']];
    if (this.props.isDragging) classNames = [...classNames, styles['is-dragging']];
    return (
      <div
        className={classNames.join(' ')}
        onMouseEnter={this.props.onMouseEnter}
        onMouseLeave={this.props.onMouseLeave}
      >
        {this.renderContent()}
        {this.renderExpandedComponent()}
      </div>
    );
  }
}

Item.Column = Column;
Item.TitleColumn = TitleColumn;
Item.ImageColumn = ImageColumn;
Item.IconColumn = IconColumn;

export default Item;

Item.propTypes = {
  onClick: PropTypes.func,
  clickable: PropTypes.bool,
  new: PropTypes.bool,
  expandable: PropTypes.bool,
  disabled: PropTypes.bool,
  iconButtons: PropTypes.node,
  expandedComponent: PropTypes.node,
  disclosureIndicator: PropTypes.bool,
  small: PropTypes.bool,
  icon: PropTypes.string,
  imageSrc: PropTypes.string,
  linkTo: PropTypes.any,
  paddingLeft: PropTypes.number,
  paddingRight: PropTypes.number,
  isDragging: PropTypes.bool,
};
Item.defaultProps = {
  onClick: () => {},
  clickable: false,
  new: false,
  small: false,
  expandable: false,
  disabled: false,
  iconButtons: null,
  expandedComponent: null,
  disclosureIndicator: false,
  linkTo: null,
  icon: null,
  imageSrc: null,
  isDragging: false,
};
