import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import Header from '../components/Header';
import Content from '../components/Content';
import { Button, Field } from 'views/components/General';
import { MigrationSelectors, MigrationOperations } from 'state/ducks/migration';
import styles from './style.module.scss';

class SparePartLocation extends Component {
  updateMode = mode => {
    this.props.updateConfig({
      spare_part_location_field: {
        $set: mode,
      },
    });
  };

  renderPlaceringLabel = () => {
    const options = this.props.amuData.placering_options.slice(0, 4);
    const totalCount = this.props.amuData.placering_options.length;
    if (totalCount === 0) {
      return (
        <div>
          <div>{this.props.amuData.placering_label[this.props.amuData.amu_language]}</div>
          <div className={[styles['subtitle'], styles['empty']].join()}>Inga värden</div>
        </div>
      );
    } else {
      return (
        <div>
          <div>{this.props.amuData.placering_label[this.props.amuData.amu_language]}</div>
          <div className={styles['subtitle']}>
            {options.map(option => (
              <div className={styles['option']}>{option[this.props.amuData.amu_language]}</div>
            ))}
            {totalCount > 4 ? <div className={styles['option']}>+ {totalCount - 4} fler...</div> : null}
          </div>
        </div>
      );
    }
  };

  renderAvdelningLabel = () => {
    const options = this.props.amuData.avdelning_options.slice(0, 4);
    const totalCount = this.props.amuData.avdelning_options.length;
    if (totalCount === 0) {
      return (
        <div>
          <div>{this.props.amuData.avdelning_label[this.props.amuData.amu_language]}</div>
          <div className={[styles['subtitle'], styles['empty']].join()}>Inga värden</div>
        </div>
      );
    } else {
      return (
        <div>
          <div>{this.props.amuData.avdelning_label[this.props.amuData.amu_language]}</div>
          <div className={styles['subtitle']}>
            {options.map(option => (
              <div className={styles['option']}>{option[this.props.amuData.amu_language]}</div>
            ))}
            {totalCount > 4 ? <div className={styles['option']}>+ {totalCount - 4} fler...</div> : null}
          </div>
        </div>
      );
    }
  };

  renderField = () => (
    <div className={styles['fields']}>
      <Field.Radio
        label={this.renderPlaceringLabel()}
        checked={this.props.configData.spare_part_location_field === 'placering'}
        onChange={() => this.updateMode('placering')}
      />
      <Field.Radio
        label={this.renderAvdelningLabel()}
        checked={this.props.configData.spare_part_location_field === 'avdelning'}
        onChange={() => this.updateMode('avdelning')}
      />
      <Field.Radio
        label="Inget av dem"
        checked={this.props.configData.spare_part_location_field === 'none'}
        onChange={() => this.updateMode('none')}
      />
    </div>
  );

  render() {
    return (
      <>
        <Header
          title="Lagerplats för reservdelar"
          subtitle={
            <span>
              I Mainter kan ni strukturera lagerplatser för era reservdelar i en trädstruktur. Detta kan ni
              konfigurera i ett senare skede under inställningssidan. Först måste vi bestämma vilket av fälten{' '}
              <span className={styles['dark']}>{this.props.amuData.placering_label[this.props.amuData.amu_language]}</span> eller{' '}
              <span className={styles['dark']}>{this.props.amuData.avdelning_label[this.props.amuData.amu_language]}</span> ni vill importera
              till funktionen lagerplatser i Mainter.
            </span>
          }
        />
        <Content width={420}>{this.renderField()}</Content>
        <Button
          primary
          label="Nästa steg"
          onClick={() => {
            this.props.completeStep(this.props.step);
            this.props.history.push(`?step=${this.props.step + 1}`);
          }}
        />
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateConfig: MigrationOperations.updateConfig,
      completeStep: MigrationOperations.completeStep,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    configData: MigrationSelectors.configData(state),
    amuData: MigrationSelectors.amuData(state),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SparePartLocation));
