import React from 'react';
import { NewSearchField as GeneralSearchField } from 'views/components/General';
import styles from './style.module.scss';

const SearchField = React.forwardRef((props, ref) => (
  <div className={!props.ignoreMarginBottom ? styles['search-container'] : null}>
    <GeneralSearchField ref={ref} {...props} />
  </div>
));

export default SearchField;
