import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./style.module.scss";
import { FormattedMessage } from "react-intl";
import { Tooltip, Icon } from "views/components/General";
import Text from "./Text";
import Textarea from "./Textarea";
import Dropdown from "./Dropdown";
import Resource from "./Resource";
import Checkbox from "./Checkbox";
import Radio from "./Radio";
import Money from "./Money";
import Decimal from "./Decimal";
import Number from "./Number";
import Time from "./Time";

class Field extends Component {
  renderIcon = () => {
    if (!this.props.icon) return null;

    return <div className={styles["icon-container"]}>{this.props.icon}</div>;
  };

  renderLabel = () => {
    let classNames = [styles["label"]];
    if (this.props.questionTooltipContent) {
      classNames = [...classNames, styles["tooltip"]];
    }
    return (
      <div className={classNames.join(" ")}>
        {this.props.label}
        {this.props.singleRow ? <span>:</span> : null}
      </div>
    );
  };

  renderQuestionTooltipContent = () => {
    if (this.props.questionTooltipContent == null) return null;
    return (
      <Tooltip
        position="top center"
        trigger={
          <div className={styles["more-info-icon-container"]}>
            <Icon regular type="question-circle" />
          </div>
        }
        label={this.props.questionTooltipContent}
      />
    );
  };

  render() {
    let classNames = [styles["field"]];
    if (this.props.view) {
      classNames = [...classNames, styles["view"]];
    } else {
      classNames = [...classNames, styles["edit"]];
    }
    if (this.props.singleRow) {
      classNames = [...classNames, styles["single-row"]];
    }

    if (this.props.alignRight) {
      classNames = [...classNames, styles["align-right"]];
    }

    return (
      <div
        className={classNames.join(" ")}
        style={{ fontSize: this.props.fontSize }}
      >
        {this.renderIcon()}
        <div className={styles["container"]}>
          <div className={styles["label-container"]}>
            {this.renderLabel()}
            {this.renderQuestionTooltipContent()}
          </div>
          {this.props.subtitle ? (
            <p className={styles["subtitle"]}>
              <FormattedMessage id={this.props.subtitle} />
            </p>
          ) : null}
          <div className={styles["content"]}>{this.props.children}</div>
        </div>
      </div>
    );
  }
}

Field.Text = Text;
Field.Number = Number;
Field.Textarea = Textarea;
Field.Dropdown = Dropdown;
Field.Resource = Resource;
Field.Checkbox = Checkbox;
Field.Radio = Radio;
Field.Money = Money;
Field.Decimal = Decimal;
Field.Time = Time;

export default Field;

Field.propTypes = {
  label: PropTypes.node,
  subtitle: PropTypes.node,
  icon: PropTypes.string,
  view: PropTypes.bool,
  singleRow: PropTypes.bool,
  fontSize: PropTypes.number,
  error: PropTypes.bool,
  alignRight: PropTypes.bool,
  questionTooltipContent: PropTypes.node,
};
Field.defaultProps = {
  view: false,
  error: false,
  singleRow: false,
  alignRight: false,
  fontSize: 13,
  questionTooltipContent: null,
};
