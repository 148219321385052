import React from 'react';
import { Icon } from 'views/components/General';

export default ({ position = 'top center', size = 12, verticalOffset = 0, horizontalOffset = 0 }) => {
  return (
    <div>
      <Icon size={size} type="lock" />
    </div>
  );
};
