import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './style.module.scss';
import Column from './Column';

class Header extends Component {
  getPaddingLeft = () => {
    const borderWidth = 1;
    if (this.props.paddingLeft !== undefined) return this.props.paddingLeft + borderWidth;
    if (this.props.expandable) return 50 + borderWidth;
    return 30 + borderWidth;
  };

  getPaddingRight = () => {
    const borderWidth = 1;
    const initialRightPadding = this.props.paddingRight || 30;

    let buttonsWidth = 0;
    if (this.props.numberOfIconButtons) {
      const iconButtonWidth = 32;
      const buttonsContainerLeftPadding = 22;
      const buttonsContainerRightMargin = -8;
      buttonsWidth =
        buttonsContainerRightMargin +
        buttonsContainerLeftPadding +
        iconButtonWidth * this.props.numberOfIconButtons;
    }

    const disclosureIndicatorWidth = this.props.disclosureIndicator ? 42 : 0;

    return borderWidth + initialRightPadding + buttonsWidth + disclosureIndicatorWidth;
  };

  renderContent = () => {
    if (this.props.noColumns) return null;
    let classNames = [styles['header']];
    if (this.props.background) classNames = [...classNames, styles['background']];
    if (this.props.expandable) classNames = [...classNames, styles['expandable']];
    if (this.props.small) classNames = [...classNames, styles['small']];
    if (this.props.icon) classNames = [...classNames, styles['icon']];
    if (this.props.image) classNames = [...classNames, styles['image']];
    if (this.props.disclosureIndicator) classNames = [...classNames, styles['disclosure-indicator']];
    if (this.props.numberOfIconButtons > 0) classNames = [...classNames, styles['has-buttons']];
    const style = {
      paddingLeft: this.getPaddingLeft(),
      paddingRight: this.getPaddingRight(),
      ...this.props.style,
    };

    return (
      <div className={classNames.join(' ')} style={style}>
        {this.props.children}
      </div>
    );
  };

  render() {
    return this.renderContent();
  }
}

Header.Column = Column;

export default Header;

Header.propTypes = {
  expandable: PropTypes.bool,
  icon: PropTypes.bool,
  image: PropTypes.bool,
  noColumns: PropTypes.bool,
  disclosureIndicator: PropTypes.bool,
  style: PropTypes.object,
  background: PropTypes.bool,
  numberOfIconButtons: PropTypes.number,
};
Header.defaultProps = {
  expandable: false,
  icon: false,
  image: false,
  noColumns: false,
  background: false,
  style: null,
  disclosureIndicator: false,
  numberOfIconButtons: 0,
};
