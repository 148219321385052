import actions from './actions';
const axios = require('axios');

const updateConfig = actions.updateConfig;
const completeStep = actions.completeStep;

const fetchImportDataWithToken = token => dispatch => {
  axios
    .get(`${process.env.REACT_APP_API_URL}imports/${token}`)
    .then(res => {
      dispatch(
        actions.fetchImportDataSuccess({
          data: res.data,
        })
      );
    })
    .catch(error => {
      dispatch(actions.fetchImportDataFailed());
    });
};

const submitConfig = (token, config) => dispatch => {
  const params = { config };
  return axios.post(`${process.env.REACT_APP_API_URL}imports/${token}/config`, params).then(res => {
    dispatch(actions.submitSuccess());
  });
};

export default {
  updateConfig,
  completeStep,
  fetchImportDataWithToken,
  submitConfig,
};
