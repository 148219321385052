import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import Header from '../components/Header';
import Content from '../components/Content';
import { Button, Field } from 'views/components/General';
import { Grid } from 'views/components/Layout';
import { MigrationSelectors, MigrationOperations } from 'state/ducks/migration';
import styles from './style.module.scss';

class Administrator extends Component {
  state = {
    users: [],
    email: this.props.configData.admin_override_email,
  };

  componentDidMount() { 
    console.log(this.props.amuData);
    const users = this.props.amuData.users
      .sort((a, b) => {
        const aName = `${a.firstname} ${a.lastname}`.trim();
        const bName = `${b.firstname} ${b.lastname}`.trim();
        return aName.localeCompare(bName)
      })

    this.setState({ users });
  }

  selectUser = user => {
    this.props.updateConfig({
      admin_id: {
        $set: user.id,
      },
    });
  };

  hasUsername = user => ![null, ''].includes(user.username);

  renderSelectedUser = () => {
    const user = this.props.amuData.users.find(user => user.id === this.props.configData.admin_id);

    if (user) {
      return this.renderUser(user);
    } else {
      return null;
    }
  };

  renderUser = user => (
    <span>
      <span>
        {user.firstname} {user.lastname}
      </span>
      {this.hasUsername(user) ? <span className={styles['username']}> - ({user.username})</span> : null}
    </span>
  );

  renderAdditionalEmailField = () => {
    const user = this.props.amuData.users.find(user => user.id === this.props.configData.admin_id);

    if (user && [null, ''].includes(user.email)) {
      return (
        <Grid.Row>
          <Grid.Column>
            <Field label="E-post">
              <Field.Text
                focus
                value={this.state.email}
                onChange={email => this.setState({ email })}
                onBlur={email => {
                  this.props.updateConfig({
                    admin_override_email: {
                      $set: email,
                    },
                  });
                }}
              />
            </Field>
          </Grid.Column>
        </Grid.Row>
      );
    }
  };

  renderSelectableUsers = () => {
    return this.state.users
      .map(user => (
        <Field.Dropdown.Item
          selected={user.id === this.props.configData.admin_id}
          onClick={() => {
            this.selectUser(user);
          }}
        >
          {this.renderUser(user)}
        </Field.Dropdown.Item>
      ));
  };

  renderField = () => (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <Field label="Användare">
            <Field.Dropdown clearable={false} value={this.renderSelectedUser()}>
              {this.renderSelectableUsers()}
            </Field.Dropdown>
          </Field>
        </Grid.Column>
      </Grid.Row>
      {this.renderAdditionalEmailField()}
    </Grid>
  );

  render() {
    return (
      <>
        <Header
          title="Administratör"
          subtitle="Välj den användare som initialt ska importeras som administratör. Resterande användare kommer att migreras men som akriverade användare som kan återupptas"
        />
        <Content width={420}>{this.renderField()}</Content>
        <Button
          primary
          label="Nästa steg"
          onClick={() => {
            if (!this.props.configData.admin_id) {
              alert('Du måste välja en användare');
              return;
            }

            const admin_user = this.props.amuData.users.find(
              user => user.id === this.props.configData.admin_id
            );

            if (
              admin_user &&
              [null, ''].includes(admin_user.email) &&
              this.props.configData.admin_override_email === ''
            ) {
              alert('Användaren måste ha en registreras e-postadress');
              return;
            }

            this.props.completeStep(this.props.step);
            this.props.history.push(`?step=${this.props.step + 1}`);
          }}
        />
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateConfig: MigrationOperations.updateConfig,
      completeStep: MigrationOperations.completeStep,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    configData: MigrationSelectors.configData(state),
    amuData: MigrationSelectors.amuData(state),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Administrator));
