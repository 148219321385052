import React, { Component } from "react";
import PropTypes from "prop-types";
import ContentLoader from "react-content-loader";
import { Link } from "react-router-dom";
import styles from "./style.module.scss";

class Item extends Component {
  wrapInLink = (children) => {
    if (!this.props.linkTo) return children;
    if (this.props.disabled) return children;
    return <Link to={this.props.linkTo}>{children}</Link>;
  };

  renderNumber = () => {
    if (this.props.number) {
      return <div className={styles["number"]}>{this.props.number}</div>;
    }
    return null;
  };

  renderRightComponent = () => {
    if (this.props.rightComponent) {
      return this.props.rightComponent;
    }
    return null;
  };

  renderSubtitle = () => {
    if (this.props.subtitle) {
      return <div className={styles["subtitle"]}>{this.props.subtitle}</div>;
    }
    return null;
  };

  renderTitle = () => {
    return (
      <>
        <div className={styles["text-container"]}>
          <div>{this.props.title}</div>
          {this.renderSubtitle()}
        </div>
      </>
    );
  };

  renderLoader = () => {
    let classNames = [styles["item"], styles["loading"]];
    return (
      <div className={classNames.join(" ")}>
        <div style={{ width: 115, height: 16 }}>
          <ContentLoader
            primaryColor="#F5F5F5"
            secondaryColor="#EFEFEF"
            width={115}
            height={16}
          >
            <rect x="0" y="0" rx="2" ry="2" width="115" height="16" />
          </ContentLoader>
        </div>
      </div>
    );
  };

  renderItem = () => {
    let classNames = [styles["item"]];
    if (this.props.selected) classNames = [...classNames, styles["selected"]];
    if (this.props.blueText) {
      classNames = [...classNames, styles["blue-text"]];
    }

    if (this.props.red) {
      classNames = [...classNames, styles["red"]];
    } else {
      classNames = [...classNames, styles["blue"]];
    }
    if (this.props.disabled) {
      classNames = [...classNames, styles["disabled"]];
    }

    return this.wrapInLink(
      <div onClick={this.props.onClick} className={classNames.join(" ")}>
        {this.renderTitle()}
        {this.renderNumber()}
        {this.renderRightComponent()}
      </div>
    );
  };

  render() {
    if (this.props.loading) {
      return this.renderLoader();
    }
    return this.renderItem();
  }
}

export default Item;

Item.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  linkTo: PropTypes.any,
  selected: PropTypes.bool,
  number: PropTypes.number,
  loading: PropTypes.bool,
};

Item.defaultProps = {
  onClick: () => {},
  disabled: false,
  selected: false,
  number: null,
  loading: false,
};
