import React from 'react';
import { Loader } from 'views/components/General';
import styles from './style.module.scss';

export default ({ children, withPadding = false, loading = true }) => {
  return (
    <div className={`${styles['loader-container']} ${withPadding ? styles['with-padding'] : ''}`}>
      {loading ? (
        <div className={styles['loader']}>
          <Loader small />
        </div>
      ) : null}
      {children}
    </div>
  );
};
