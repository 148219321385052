import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import queryString from 'query-string';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { withRouter } from 'react-router';
import MigrationHelper from 'utilities/MigrationHelper';
import { Icon, Menu as GeneralMenu } from 'views/components/General';
import { MigrationSelectors, MigrationOperations } from 'state/ducks/migration';
import Logo from 'assets/images/logo-mainter-dark.png';
import styles from './style.module.scss';

class Menu extends Component {
  goToStep = step => {
    this.props.history.push(`?step=${step}`);
  };

  reset = () => {
    this.props.history.push('?');
  };

  render() {
    const { step } = queryString.parse(this.props.location.search);
    const totalSteps = MigrationHelper.steps.length;

    return (
      <PerfectScrollbar>
        <div className={styles['menu']}>
          <div className={styles['logo']}>
            <img alt="logo" src={Logo} />
          </div>
          <GeneralMenu.Item
            selected={step === 1 || step == null}
            title={`1. Välkommen`}
            rightComponent={
              this.props.completedSteps[1] ? (
                <div style={{ position: 'relative', width: 14, height: 14 }}>
                  <Icon type="check" green regular />
                </div>
              ) : null
            }
            onClick={() => {
              this.goToStep(1);
            }}
          />
          {MigrationHelper.steps.map((item, i) => (
            <GeneralMenu.Item
              key={i}
              disabled={!this.props.completedSteps[i + 1]}
              selected={step === (i + 2).toString()}
              title={`${i + 2}. ${item}`}
              rightComponent={
                this.props.completedSteps[i + 2] ? (
                  <div style={{ position: 'relative', width: 14, height: 14 }}>
                    <Icon type="check" green regular />
                  </div>
                ) : null
              }
              onClick={() => {
                this.goToStep(i + 2);
              }}
            />
          ))}
          <GeneralMenu.Item
            disabled={!this.props.completedSteps[totalSteps]}
            selected={step === (totalSteps + 2).toString()}
            title={`${totalSteps + 2}. Skicka in`}
            onClick={() => {
              this.goToStep(totalSteps + 2);
            }}
          />
        </div>
      </PerfectScrollbar>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchImportDataWithToken: MigrationOperations.fetchImportDataWithToken,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    // amuData: MigrationSelectors.amuData(state),
    completedSteps: MigrationSelectors.completedSteps(state),
    loading: MigrationSelectors.loading(state),
    notFound: MigrationSelectors.notFound(state),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Menu));
