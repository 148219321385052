import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import Header from '../components/Header';
import Content from '../components/Content';
import { Button, Field, Icon } from 'views/components/General';
import { MigrationSelectors, MigrationOperations } from 'state/ducks/migration';
import styles from './style.module.scss';

class WorkOrderUserAssignees extends Component {
  state = {
    users: [],
  };

  componentDidMount() { 
    console.log(this.props.amuData);
    const users = this.props.amuData.users
      .sort((a, b) => {
        const aName = `${a.firstname} ${a.lastname}`.trim();
        const bName = `${b.firstname} ${b.lastname}`.trim();
        return aName.localeCompare(bName)
      })

    this.setState({ users });
  }

  update = (groupId, userId) => {
    this.props.updateConfig({
      work_order_user_assignees: {
        [groupId]: {
          $set: userId,
        },
      },
    });
  };

  clear = groupId => {
    this.props.updateConfig({
      work_order_user_assignees: {
        $unset: [groupId],
      },
    });
  };

  hasUsername = user => ![null, ''].includes(user.username);
  renderUser = user => {
    if (!user) return null;
    return (
      <span>
        <span>
          {user.firstname} {user.lastname}
        </span>
        {this.hasUsername(user) ? <span className={styles['username']}> - ({user.username})</span> : null}
      </span>
    );
  }

  renderSelectableUsers = (groupId) => {
    const selectedUserId = this.props.configData.work_order_user_assignees[groupId];

    return this.state.users
      .map(user => (
        <Field.Dropdown.Item
          selected={user.id === selectedUserId}
          onClick={() => {
            this.update(groupId, user.id);
          }}
        >
          {this.renderUser(user)}
        </Field.Dropdown.Item>
      ));
  };

  renderAssignee = assignee => {
    const selectedUserId = this.props.configData.work_order_user_assignees[assignee.id];
    const selectedUser = this.props.amuData.users
      .find(user => user.id === selectedUserId);

    return (
      <div className={styles['row']}>
        <div className={styles['left-panel']}>{assignee[this.props.amuData.amu_language]}</div>
        <div className={styles['arrow']}>
          <Icon solid type="arrow-alt-right" />
        </div>
        <div className={styles['right-panel']}>
          <Field.Dropdown
            onClear={() => { this.clear(assignee.id) }}
            value={this.renderUser(selectedUser)}
          >
            {this.renderSelectableUsers(assignee.id)}
          </Field.Dropdown>
        </div>
      </div >
    );
  };

  renderAssignees = () => {
    if (this.props.amuData.work_order_assignee_groups.length === 0) {
      return <div className={styles['empty']}>Det finns inga grupper</div>;
    }

    return (
      <div>
        {this.props.amuData.work_order_assignee_groups.map(this.renderAssignee)}
      </div>
    );
  };

  render() {
    return (
      <>
        <Header
          title="Tilldela arbetsordrar till användare"
          subtitle={<span>I Mainter är det möjligt att tilldela arbetsordrar direkt till användare. Bestäm ifall arbetsordrar under migreringen ska tilldelas direkt till en användare istället för en grupp.<br /><br />Rekommenderas ifall gruppen är skapad för en specifik person.</span>}
        />
        <Content width={420}>{this.renderAssignees()}</Content>
        <Button
          primary
          label="Nästa steg"
          onClick={() => {
            this.props.completeStep(this.props.step);
            this.props.history.push(`?step=${this.props.step + 1}`);
          }}
        />
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateConfig: MigrationOperations.updateConfig,
      completeStep: MigrationOperations.completeStep,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    configData: MigrationSelectors.configData(state),
    amuData: MigrationSelectors.amuData(state),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkOrderUserAssignees));
