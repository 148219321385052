import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import queryString from 'query-string';
import { Loader } from 'views/components/General';
import { MigrationSelectors, MigrationOperations } from 'state/ducks/migration';
import styles from './style.module.scss';
import Menu from './Menu';
import Welcome from './Steps/1_Welcome';
import Administrator from './Steps/2_Administrator';
import Language from './Steps/3_Language';
import Timezone from './Steps/4_Timezone';
import VendorCategories from './Steps/5_VendorCategories';
import WorkOrderPriorities from './Steps/6_WorkOrderPriorities';
import WorkOrderCategories from './Steps/7_WorkOrderCategories';
import WorkOrderUserAssignees from './Steps/8_WorkOrderUserAssignees';
import WorkOrderVendorAssignees from './Steps/9_WorkOrderVendorAssignees';
import RequestPriorities from './Steps/10_RequestPriorities';
import SparePartLocation from './Steps/11_SparePartLocation';
import AssetNumber from './Steps/12_AssetNumber';
import ArchivedAssets from './Steps/13_ArchivedAssets';
import Submit from './Steps/14_Submit';

class Form extends Component {
  constructor(props) {
    super(props);

    this.props.fetchImportDataWithToken(props.match.params.token);
  }

  componentDidUpdate(prevProps) {
    const { step: newStep } = queryString.parse(this.props.location.search);

    if (newStep != null && !this.props.completedSteps[parseInt(newStep) - 1]) {
      this.props.history.push('?');
    }
  }

  renderLoader = () => (
    <div className={styles['loader']}>
      <Loader />
    </div>
  );

  renderSuccess = () => (
    <div className={styles['success']}>
      Formuläret har skickats in. Vi kontaktar dig inom kort med inloggningsuppgifter.
    </div>
  );

  renderNotFound = () => <div className={styles['not-found']}>Ops.. Länken verkar inte fungera.</div>;

  renderContent = () => {
    const { step } = queryString.parse(this.props.location.search);

    switch (step) {
      case '2':
        return <Administrator step={2} />;
      case '3':
        return <Language step={3} />;
      case '4':
        return <Timezone step={4} />;
      case '5':
        return <VendorCategories step={5} />;
      case '6':
        return <WorkOrderPriorities step={6} />;
      case '7':
        return <WorkOrderCategories step={7} />;
      case '8':
        return <WorkOrderUserAssignees step={8} />;
      case '9':
        return <WorkOrderVendorAssignees step={9} />;
      case '10':
        return <RequestPriorities step={10} />;
      case '11':
        return <SparePartLocation step={11} />;
      case '12':
        return <AssetNumber step={12} />;
      case '13':
        return <ArchivedAssets step={13} />;
      case '14':
        return <Submit step={14} />;
      default:
        return <Welcome step={1} />;
    }
  };

  render() {
    if (this.props.loading) return this.renderLoader();
    if (this.props.success) return this.renderSuccess();
    if (this.props.notFound) return this.renderNotFound();
    return (
      <>
        <div className={styles['menu']}>
          <Menu />
        </div>
        <div className={styles['content']}>{this.renderContent()}</div>
      </>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchImportDataWithToken: MigrationOperations.fetchImportDataWithToken,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    completedSteps: MigrationSelectors.completedSteps(state),
    loading: MigrationSelectors.loading(state),
    notFound: MigrationSelectors.notFound(state),
    success: MigrationSelectors.success(state),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Form));
