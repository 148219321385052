import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import Header from '../components/Header';
import Content from '../components/Content';
import { Button, Field } from 'views/components/General';
import AnimateHeight from 'react-animate-height';
import { MigrationSelectors, MigrationOperations } from 'state/ducks/migration';
import styles from './style.module.scss';

class VendorCategories extends Component {
  updateMode = mode => {
    this.props.updateConfig({
      company_mode: {
        $set: mode,
      },
    });
  };

  toogleCategory = category => {
    if (this.props.configData.vendor_categories.includes(category.id)) {
      this.props.updateConfig({
        vendor_categories: {
          $apply: ids => ids.filter(id => id !== category.id),
        },
      });
    } else {
      this.props.updateConfig({
        vendor_categories: {
          $unshift: [category.id],
        },
      });
    }
  };

  renderCategories = () => {
    return (
      <AnimateHeight duration={250} height={this.props.configData.company_mode === 'categories' ? 'auto' : 0}>
        <div className={styles['categories']}>
          {this.props.amuData.vendor_categories.map(category => (
            <Field.Checkbox
              label={
                <span>
                  {category[this.props.amuData.amu_language]} <span className={styles['count']}> - {category.count} st</span>
                </span>
              }
              checked={this.props.configData.vendor_categories.includes(category.id)}
              onChange={() => {
                this.toogleCategory(category);
              }}
            />
          ))}
        </div>
      </AnimateHeight>
    );
  };

  renderField = () => (
    <>
      <Field.Radio
        label="Alla företag"
        checked={this.props.configData.company_mode === 'all'}
        onChange={() => this.updateMode('all')}
      />
      <Field.Radio
        label="Endast vissa kategorier"
        checked={this.props.configData.company_mode === 'categories'}
        onChange={() => this.updateMode('categories')}
      />
      {this.renderCategories()}
      <Field.Radio
        label="Inga företag"
        checked={this.props.configData.company_mode === 'none'}
        onChange={() => this.updateMode('none')}
      />
    </>
  );

  render() {
    return (
      <>
        <Header
          title="Leverantörer"
          subtitle="Bestäm vilka typer av företag som ska importeras till listan leverantörer i Mainter. Resterande företag kommer inte importeras."
        />
        <Content width={420}>{this.renderField()}</Content>
        <Button
          primary
          label="Nästa steg"
          onClick={() => {
            const { vendor_categories, company_mode } = this.props.configData;
            if (company_mode === 'categories' && vendor_categories.length === 0) {
              alert('Du måste välja minst en kategori');
              return;
            }

            this.props.completeStep(this.props.step);
            this.props.history.push(`?step=${this.props.step + 1}`);
          }}
        />
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateConfig: MigrationOperations.updateConfig,
      completeStep: MigrationOperations.completeStep,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    configData: MigrationSelectors.configData(state),
    amuData: MigrationSelectors.amuData(state),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VendorCategories));
