import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './style.module.scss';
import Item from './Item';
import Header from './Header';

/*
  Usage docs

  <List.Header
    whiteCard
    whiteCardTitle
    whiteCardButton
    expandable
    icon
    image
    noColumns
    disclosureIndicator
    numberOfIconButtons
  >
    <List.Header.Column flex >Flexable</List.Header.Column>
    <List.Header.Column width={300}>Titel</List.Header.Column>
    <List.Header.Column width={300}>Titel</List.Header.Column>
    <List.Header.Column width={100}></List.Header.Column>
  <List.Header>
  <List>
    <List.Item
      clickable
      onClick
      new
      expandable
      expandedComponent
      disclosureIndicator
      icon
      imageSrc
      linkTo
      iconButtons
    >
      <List.Item.Column flex>
        <List.Item.Column.Title title='title' subtitle="subtitle"></List.Item.Column.Title>
      </List.Item.Column>
      <List.Item.Column width={200}>{this.renderFolder()}</List.Item.Column>
      <List.Item.Column width={200}>{this.renderCreatedBy()}</List.Item.Column>
      <List.Item.Column width={200}>{this.renderDisclosureIcon()}</List.Item.Column>
    </List.Item>
  </List>
*/

class List extends Component {
  renderChildren = () => {
    const { light, small } = this.props;
    const children = React.Children.toArray(this.props.children).filter(o => o);
    return React.Children.map(children, child => {
      return React.cloneElement(child, { light, small });
    });
  };

  render() {
    let classNames = [styles['list']];
    if (this.props.light) classNames = [...classNames, styles['light']];
    if (this.props.usedInWhiteCard) classNames = [...classNames, styles['used-in-white-card']];
    if (this.props.draggable) classNames = [...classNames, styles['draggable']];

    return <div className={classNames.join(' ')}>{this.renderChildren()}</div>;
  }
}

List.Item = Item;
List.Header = Header;

export default List;

List.propTypes = {
  light: PropTypes.bool,
  small: PropTypes.bool,
  usedInWhiteCard: PropTypes.bool,
};
List.defaultProps = {
  light: false,
  small: false,
  usedInWhiteCard: false,
};
