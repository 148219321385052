import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ContentLoader from 'react-content-loader';
import { Icon } from 'views/components/General';
import Column from '../Column';
import styles from './style.module.scss';

export default class ImageColumn extends Component {
  renderImage = () => {
    if (this.props.loading) {
      return (
        <div style={{ width: this.props.size, height: this.props.size }}>
          <ContentLoader viewBox={`0 0 ${this.props.size} ${this.props.size}`} preserveAspectRatio="xMinYMin">
            <circle cx={this.props.size / 2} cy={this.props.size / 2} r={this.props.size / 2} />
          </ContentLoader>
        </div>
      );
    }
    if (this.props.imageId == null) {
      return (
        <div className={styles['image-container']}>
          <Icon type="image" />
        </div>
      );
    }
    return (
      <div
        className={styles['image-container']}
        style={{ backgroundImage: `url(${process.env.REACT_APP_BROWSER_URL}images/${this.props.imageId})` }}
      />
    );
  };

  render() {
    return <Column width={this.props.size}>{this.renderImage()}</Column>;
  }
}

ImageColumn.propTypes = {
  size: PropTypes.number,
};

ImageColumn.defaultProps = {
  size: 30,
};
