import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import Header from '../components/Header';
import { Button } from 'views/components/General';
import { MigrationOperations } from 'state/ducks/migration';
import styles from './style.module.scss';

class Welcome extends Component {
  renderSubtitle = () => (
    <span className={styles['subtitle']}>
      För att kunna migrera till Mainter behöver vi mer information av dig. Därför har vi tagit fram detta
      formulär där vi går igenom hur vi migrerar just din data.
      <br />
      <br />
      Här finns ett dokument som beskriver hur imorten genomförs och vilken data som följer med.&nbsp;
      <a
        href="https://qs.amsystem.com/d/?d=c8d46856c61627a20cbc3b600fb1c264e5f19c94aafa41ada32d00a3603b227fbd8d4e2afc7df1738275147f2e596809"
        rel="noopener noreferrer"
        target="_blank"
      >
        Klicka här
      </a>
      <br />
      <br />
      Denna migrering påverkar inte er information sparad i AM Underhåll på något vis.
      <br />
      <br />
      <span className={styles['red']}>Läs noggrant igenom så ni inte missar någon information.</span>
    </span>
  );

  render() {
    return (
      <>
        <Header title="Migrering från AM System" subtitle={this.renderSubtitle()} />
        <Button
          primary
          label="Starta formulär"
          onClick={() => {
            window.onbeforeunload = () => true;
            this.props.completeStep(this.props.step);
            this.props.history.push(`?step=${this.props.step + 1}`);
          }}
        />
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      completeStep: MigrationOperations.completeStep,
    },
    dispatch
  );
}

export default withRouter(connect(null, mapDispatchToProps)(Welcome));
