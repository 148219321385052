import { Component } from 'react';

import Item from './Item';

class Menu extends Component {
  render() {
    return this.props.children;
  }
}

Menu.Item = Item;

export default Menu;
