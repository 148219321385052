/*
  --------------------
    DATA SELECTORS
  --------------------
*/

/*
  --------------------
    UI SELECTORS
  --------------------
*/

const amuData = state => state.migration.amuData;
const configData = state => state.migration.configData;
const loading = state => state.migration.loading;
const notFound = state => state.migration.notFound;
const completedSteps = state => state.migration.completedSteps;
const success = state => state.migration.success;

export default {
  amuData,
  configData,
  loading,
  notFound,
  completedSteps,
  success,
};
