import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Icon, NewSearchField } from 'views/components/General';
import styles from './style.module.scss';
import TabBar from './TabBar';
import TabBarItem from './TabBarItem';

class Header extends Component {
  hasIcons = () => this.props.iconButtons != null || this.props.hideCloseButton === false;

  renderTabBar = () => {
    if (!this.props.tabBarComponent) return null;

    return this.props.tabBarComponent;
  };

  renderButtons = () =>
    this.props.buttons == null ? null : (
      <div className={`${styles['buttons']} ${this.hasIcons() ? styles['has-icons'] : ''}`}>
        {this.props.buttons}
      </div>
    );

  getTitlePlaceholder = () => {
    if (this.props.titlePlaceholder != null) {
      return this.props.intl.formatMessage({ id: this.props.titlePlaceholder });
    }
    return this.props.intl.formatMessage({ id: 'general.add-title' });
  };

  onBlurTitle = () => {
    this.props.onBlurTitle(this.state.dataTitle);
    this.props.onFocusChange(false);
  };

  onChangeTitle = value => {
    this.setState({ dataTitle: value });
    this.props.onChangeTitle(value);
  };

  renderTitle = () => (
    <div className={styles['title-container']}>
      <div className={styles['title']}>{this.props.title}</div>
      {this.renderSubtitle()}
    </div>
  );

  renderSearchField = () => {
    if (!this.props.searchable) return;
    return (
      <div className={styles['search-container']}>
        <NewSearchField
          value={this.props.value}
          debounce={this.props.debounce}
          placeholder={this.props.intl.formatMessage({
            id: 'general.search-placeholder',
          })}
          onDebouncedSearch={value => {
            this.props.onDebouncedSearch(value);
          }}
          onSearch={value => {
            this.props.onSearch(value);
          }}
          onClear={() => {
            this.props.onClear();
          }}
        />
      </div>
    );
  };

  renderSubtitle = () => {
    if (!this.props.subtitle) return null;
    let classNames = [styles['subtitle']];
    if (this.props.subtitleTopMargin) {
      classNames = [...classNames, styles['top-margin']];
    }

    return (
      <div className={styles['subtitle-container']}>
        <div className={classNames.join(' ')}>{this.props.subtitle}</div>
      </div>
    );
  };

  renderLoader = () => {
    return (
      <p className={styles['loader']}>
        <FormattedMessage id="general.loading" />
      </p>
    );
  };

  renderCloseButton = () => {
    if (this.props.hideCloseButton) return null;

    return (
      <div className={styles['close-container']}>
        {this.props.iconButtons ? <div className={styles['separator']} /> : null}
        <Button type="icon" icon={<Icon light size={18} type="times" />} onClick={this.props.onClose} />
      </div>
    );
  };

  renderBackButton = () => {
    if (!this.props.showBackButton) return null;

    return (
      <div className={styles['back-button-container']}>
        <Button type="icon" icon={<Icon light size={28} type="angle-left" />} onClick={this.props.onBack} />
      </div>
    );
  };

  renderContent = () => (
    <React.Fragment>
      <div className={styles['header-top']}>
        {this.renderBackButton()}
        {this.renderTitle()}
        {this.renderSearchField()}
        <div className={styles['buttons-container']}>
          {this.renderButtons()}
          <div
            className={this.props.searchable ? styles['icon-buttons'] : styles['icon-buttons-without-search']}
          >
            {this.props.iconButtons}
            {this.renderCloseButton()}
          </div>
        </div>
      </div>
      {this.renderTabBar()}
    </React.Fragment>
  );

  render() {
    let classNames = [styles['header']];
    if (this.props.tabBarComponent != null) {
      classNames = [...classNames, styles['has-tabs']];
    }
    return (
      <React.Fragment>
        <header className={classNames.join(' ')}>
          {this.props.loading ? this.renderLoader() : this.renderContent()}
        </header>
        {this.props.border ? <div className={styles['border']} /> : null}
      </React.Fragment>
    );
  }
}

Header.TabBar = TabBar;
Header.TabBarItem = TabBarItem;

export default injectIntl(Header);

Header.propTypes = {
  onClose: PropTypes.func,
  tabBarComponent: PropTypes.element,
  buttons: PropTypes.node,
  iconButtons: PropTypes.node,
  onSelectTab: PropTypes.func,
  title: PropTypes.element,
  subtitle: PropTypes.element,
  subtitleTopMargin: PropTypes.bool,
  loading: PropTypes.bool,
  border: PropTypes.bool,
  hideCloseButton: PropTypes.bool,
  showBackButton: PropTypes.bool,
  onBack: PropTypes.func,
  searchable: PropTypes.bool,
  debounce: PropTypes.bool,
  onSearch: PropTypes.func,
  onDebouncedSearch: PropTypes.func,
};

Header.defaultProps = {
  title: null,
  subtitle: null,
  tabBarComponent: null,
  buttons: null,
  onSelectTab: () => null,
  onBack: () => null,
  loading: false,
  hideCloseButton: false,
  showBackButton: false,
  border: false,
  searchable: false,
  debounce: false,
  onSearch: () => {},
  onDebouncedSearch: () => {},
};
