const FETCH_IMPORT_DATA_SUCCESS = 'migration/fetch_import_data_success';
const FETCH_IMPORT_DATA_FAILED = 'migration/fetch_import_data_failed';
const UPDATE_CONFIG = 'migration/update_config';
const COMPLETE_STEP = 'migration/complete_step';
const SUBMIT_SUCCESS = 'migration/submit_success';

export default {
  FETCH_IMPORT_DATA_SUCCESS,
  FETCH_IMPORT_DATA_FAILED,
  UPDATE_CONFIG,
  COMPLETE_STEP,
  SUBMIT_SUCCESS,
};
