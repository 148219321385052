import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import Header from '../components/Header';
import Content from '../components/Content';
import { Button, Field, Icon } from 'views/components/General';
import { MigrationSelectors, MigrationOperations } from 'state/ducks/migration';
import styles from './style.module.scss';

class WorkOrderVendorAssignees extends Component {
  state = {
    vendorsToImport: []
  }

  componentDidMount() {
    if (this.props.configData.company_mode == 'categories') {
      const vendorCategories = this.props.configData.vendor_categories;

      const vendors = this.props.amuData.vendors
        .filter(vendorData => {
          return vendorData.category_ids.some(id => vendorCategories.includes(id))
        })
        .sort((a, b) => a.name.localeCompare(b.name))

      this.setState({ vendorsToImport: vendors });
    } else if (this.props.configData.company_mode == 'all') {

      const vendors = this.props.amuData.vendors
        .sort((a, b) => a.name.localeCompare(b.name))

      this.setState({ vendorsToImport: vendors });
    } else {
      this.setState({ vendorsToImport: [] });
    }
  }

  update = (groupId, vendorId) => {
    this.props.updateConfig({
      work_order_vendor_assignees: {
        [groupId]: {
          $set: vendorId,
        },
      },
    });
  };

  clear = groupId => {
    this.props.updateConfig({
      work_order_vendor_assignees: {
        $unset: [groupId],
      },
    });
  };

  renderVendor = vendor => {
    if (!vendor) return null;
    return vendor.name;
  }

  renderSelectableVendors = (groupId) => {
    const selectedVendorId = this.props.configData.work_order_vendor_assignees[groupId];

    return this.state.vendorsToImport
      .map(vendor => (
        <Field.Dropdown.Item
          selected={vendor.id === selectedVendorId}
          onClick={() => {
            this.update(groupId, vendor.id);
          }}
        >
          {this.renderVendor(vendor)}
        </Field.Dropdown.Item>
      ));
  };

  renderValue = assignee => {
    const selectedUserId = this.props.configData.work_order_user_assignees[assignee.id];
    const selectedVendorId = this.props.configData.work_order_vendor_assignees[assignee.id];

    if (selectedUserId != null) {
      const selectedUser = this.props.amuData.users
        .find(user => user.id === selectedUserId);

      return (
        <span>
          <span>
            {selectedUser.firstname} {selectedUser.lastname}
          </span>
          {![null, ''].includes(selectedUser.username) ? <span className={styles['username']}> - ({selectedUser.username})</span> : null}
        </span>
      );
    }

    if (selectedVendorId === 'CREATE_NEW') {
      return "Skapa ny leverantör"
    }
    const selectedVendor = this.props.amuData.vendors
      .find(vendor => vendor.id === selectedVendorId);

    if (selectedVendor) return selectedVendor.name;
  }

  renderAssignee = assignee => {
    const selectedVendorId = this.props.configData.work_order_vendor_assignees[assignee.id];
    const selectedUserId = this.props.configData.work_order_user_assignees[assignee.id];

    return (
      <div className={styles['row']}>
        <div className={styles['left-panel']}>{assignee[this.props.amuData.amu_language]}</div>
        <div className={styles['arrow']}>
          <Icon solid type="arrow-alt-right" />
        </div>
        <div className={styles['right-panel']}>
          <Field.Dropdown
            onClear={() => { this.clear(assignee.id) }}
            value={this.renderValue(assignee)}
            disabled={selectedUserId != null}
          >
            <Field.Dropdown.Item
              selected={selectedVendorId === 'CREATE_NEW'}
              onClick={() => {
                this.update(assignee.id, 'CREATE_NEW');
              }}
            >
              Skapa ny leverantör
            </Field.Dropdown.Item>
            <Field.Dropdown.Separator />
            {this.renderSelectableVendors(assignee.id)}
          </Field.Dropdown>
        </div>
      </div >
    );
  };

  renderAssignees = () => {
    if (this.props.amuData.work_order_assignee_groups.length === 0) {
      return <div className={styles['empty']}>Det finns inga grupper</div>;
    }
    if (this.state.vendorsToImport.length === 0) {
      return <div className={styles['empty']}>Det finns inga leverantörer att importera</div>;
    }


    return (
      <div>
        {this.props.amuData.work_order_assignee_groups.map(this.renderAssignee)}
      </div>
    );
  };

  render() {
    return (
      <>
        <Header
          title="Tilldela arbetsordrar till leverantörer"
          subtitle={<span>I Mainter är det även möjligt att tilldela arbetsordrar direkt till leverantörer. Bestäm ifall arbetsordrar under migreringen ska tilldelas direkt till en leverantör istället för en grupp.<br /><br />Rekommenderas ifall gruppen är skapad för en specifik extern leverantör.</span>}
        />
        <Content width={420}>{this.renderAssignees()}</Content>
        <Button
          primary
          label="Nästa steg"
          onClick={() => {
            this.props.completeStep(this.props.step);
            this.props.history.push(`?step=${this.props.step + 1}`);
          }}
        />
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateConfig: MigrationOperations.updateConfig,
      completeStep: MigrationOperations.completeStep,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    configData: MigrationSelectors.configData(state),
    amuData: MigrationSelectors.amuData(state),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkOrderVendorAssignees));
