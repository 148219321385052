import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './style.module.scss';
import { CSSTransition } from 'react-transition-group';
import Header from './Header';
import Container from './Container';

class SlideOutModal extends Component {
  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      document.addEventListener('keydown', this.handleKeyDown);
    } else if (prevProps.open && !this.props.open) {
      document.removeEventListener('keydown', this.handleKeyDown);
    }
  }

  handleKeyDown = e => {
    const { closeOnEscape } = this.props;
    if ((e.key === 'Escape' || e.key === 'Esc') && closeOnEscape) {
      this.props.onClose();
    }
  };

  onClickOverlay = () => {
    const { closeOnClickOverlay } = this.props;
    if (closeOnClickOverlay) {
      this.props.onClose();
    }
  };

  renderContent = () => {
    return <div className={styles['content']}>{this.props.children}</div>;
  };

  renderFooter = () => {
    if (!this.props.footerComponent) return null;
    return <div className={styles['footer']}>{this.props.footerComponent}</div>;
  };

  render() {
    return (
      <React.Fragment>
        <CSSTransition
          in={this.props.open}
          timeout={200}
          mountOnEnter
          unmountOnExit
          classNames={{
            enter: styles['modal-container-enter'],
            enterActive: styles['modal-container-enter-active'],
            exit: styles['modal-container-exit'],
            exitActive: styles['modal-container-exit-active'],
          }}
        >
          <div
            className={`new-resource-modal ${styles['modal-container']}`}
            style={{ width: this.props.width }}
          >
            <div className={styles['modal']}>
              {this.renderContent()}
              {this.renderFooter()}
            </div>
          </div>
        </CSSTransition>
        <CSSTransition
          in={this.props.open}
          timeout={320}
          mountOnEnter
          unmountOnExit
          classNames={{
            enter: styles['overlay-enter'],
            enterActive: styles['overlay-enter-active'],
            exit: styles['overlay-exit'],
            exitActive: styles['overlay-exit-active'],
          }}
        >
          <div className={`new-resource-modal ${styles['overlay']}`} onClick={this.onClickOverlay} />
        </CSSTransition>
      </React.Fragment>
    );
  }
}

SlideOutModal.Header = Header;
SlideOutModal.Container = Container;

SlideOutModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  footerComponent: PropTypes.element,
  width: PropTypes.number,
  closeOnEscape: PropTypes.bool,
  closeOnClickOverlay: PropTypes.bool,
};

SlideOutModal.defaultProps = {
  onClose: () => {},
  width: 440,
  open: false,
  closeOnEscape: true,
  closeOnClickOverlay: true,
};

export default SlideOutModal;
