import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { Form } from "views/scenes";
import styles from "./style.module.scss";

export default class Routes extends Component {
  renderSvg = () => {
    return (
      <svg
        width="1556"
        height="547"
        viewBox="0 0 1556 547"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
      >
        <path
          d="M0 0H1556V547C1556 547 873.566 368.976 468.638 368.976C63.7102 368.976 0 478.38 0 478.38V0Z"
          fill="url(#paint0_linear_22_1217)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_22_1217"
            x1="778"
            y1="0"
            x2="778"
            y2="624.235"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="white" stop-opacity="0" />
            <stop offset="1" stop-color="white" stop-opacity="0.07" />
          </linearGradient>
        </defs>
      </svg>
    );
  };

  render() {
    return (
      <div className={styles["app"]}>
        {this.renderSvg()}
        <div className={styles["wrapper"]}>
          <div className={styles["container"]}>
            <Switch>
              <Route exact path="/:token" component={Form} />
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}
