import React, { Component } from 'react';
import { Icon } from 'views/components/General';
import PropTypes from 'prop-types';
import styles from './style.module.scss';

export default class Pagination extends Component {
  constructor(props) {
    super(props);
    const { currentPage } = props;
    this.state = {
      currentPage,
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.currentPage !== this.props.currentPage &&
      this.props.currentPage !== this.state.currentPage
    ) {
      this.setState({ currentPage: this.props.currentPage });
    }
  }

  getStartingPageNumber = () => {
    const { totalPages } = this.props;
    const { currentPage } = this.state;
    if (currentPage - 2 < 1) return 1;
    if (currentPage + 2 >= totalPages && totalPages >= 5) return totalPages - 4;
    return currentPage - 2;
  };

  renderPageNumbers = () => {
    const { totalPages } = this.props;
    const { currentPage } = this.state;
    const lastPageNumberIsShowing = currentPage + 4 > totalPages;
    const getLastPageNumber = currentPage > 2 ? currentPage - 2 + 4 : currentPage + 4;
    const pageNumbers = [];
    for (
      let pageNumber = this.getStartingPageNumber();
      pageNumber <= (lastPageNumberIsShowing ? totalPages : getLastPageNumber);
      pageNumber++
    ) {
      if (pageNumbers.length === 5) {
        return pageNumbers;
      } else {
        pageNumbers.push(
          <div
            className={`${styles['page']} ${currentPage === pageNumber ? styles['selected'] : ''}`}
            onClick={() => (pageNumber === currentPage ? null : this.selectPage(pageNumber))}
          >
            {pageNumber}
          </div>
        );
      }
    }
    return pageNumbers;
  };

  selectPage = page => {
    this.setState({
      currentPage: page,
    });
    this.props.onSelectPage(page);
  };

  render() {
    const { currentPage } = this.state;
    const { totalPages } = this.props;
    return (
      <div className={styles['pagination-container']}>
        <div className={styles['pagination']}>
          {totalPages <= 5 ? null : (
            <div
              className={`${styles['page']} ${styles['previous']} ${
                currentPage === 1 ? styles['disabled'] : ''
              }`}
              onClick={() => (currentPage === 1 ? null : this.selectPage(1))}
            >
              <Icon regular type="angle-double-left" />
            </div>
          )}
          <div
            className={`${styles['page']} ${styles['previous']} ${
              currentPage === 1 ? styles['disabled'] : ''
            }`}
            onClick={() => (currentPage === 1 ? null : this.selectPage(currentPage - 1))}
          >
            <Icon regular type="angle-left" />
          </div>
          {this.renderPageNumbers()}
          <div
            className={`${styles['page']} ${styles['next']} ${
              currentPage === totalPages ? styles['disabled'] : ''
            }`}
            onClick={() => (currentPage === totalPages ? null : this.selectPage(currentPage + 1))}
          >
            <Icon regular type="angle-right"></Icon>
          </div>
          {totalPages <= 5 ? null : (
            <div
              className={`${styles['page']} ${styles['next']} ${
                currentPage === totalPages ? styles['disabled'] : ''
              }`}
              onClick={() => (currentPage === totalPages ? null : this.selectPage(totalPages))}
            >
              <Icon regular type="angle-double-left" />
            </div>
          )}
        </div>
      </div>
    );
  }
}

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  onSelectPage: PropTypes.func.isRequired,
};
