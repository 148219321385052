import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'views/components/General';
import styles from './style.module.scss';

export default class PathItem extends Component {
  render() {
    return (
      <div
        className={`
          ${styles['item']}
          ${this.props.clickable ? styles['clickable'] : ''}
          ${this.props.active ? styles['active'] : ''}
          ${this.props.small ? styles['small'] : ''}
        `}
        onClick={() => (this.props.clickable ? this.props.onClick() : null)}
      >
        {this.props.children}
        {this.props.caret ? <Icon type="caret-down" /> : null}
      </div>
    );
  }
}

PathItem.propTypes = {
  onClick: PropTypes.func,
  clickable: PropTypes.bool,
  caret: PropTypes.bool,
  maxHeight: PropTypes.number,
  children: PropTypes.element,
  small: PropTypes.bool,
};

PathItem.defaultProps = {
  onClick: () => {},
  clickable: false,
  caret: false,
  small: false,
};
