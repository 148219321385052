/*
  ------------------
    Swedish
  ------------------
*/
const steps = [
  'Administratör',
  'Språk',
  'Tidszon',
  'Leverantörer',
  'Prioritet (Arbetsorder)',
  'Kategori (Arbetsorder)',
  'Utförs av användare',
  'Utförs av leverantör',
  'Prioritet (Arbetsbegäran)',
  'Lagerplatser',
  'Löpnummer objekt',
  'Vilande objekt',
];

export default {
  steps,
};
