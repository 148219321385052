import React, { Component } from 'react';
import Currencies from 'currency-format';
import Decimal from '../Decimal';

export default class Money extends Component {
  render() {
    return (
      <Decimal
        {...this.props}
        rightLabel={Currencies[this.props.currency].symbol.grapheme}
        value={this.props.value}
      />
    );
  }
}
