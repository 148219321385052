import types from './types';
import update from 'immutability-helper';
import MigrationHelper from 'utilities/MigrationHelper';

const INITIAL_STATE = {
  // amuData is data extracted from the export
  amuData: null,
  configData: {
    admin_id: null,
    admin_override_email: '',
    lang: 'swe',
    new_lang: 'sv',
    timezone: 'Europe/Stockholm',
    company_mode: 'all',
    spare_part_location_field: 'placering',
    vendor_categories: [],
    ignore_work_orders_for_inactive_asset: false,
    work_order_priorities: {
      low: [],
      medium: [],
      high: [],
    },
    work_order_categories: {
      preventive: [],
      preventive_condition_based: [],
      preventive_predetermined: [],
      corrective: [],
      corrective_defered: [],
      corrective_immediate: [],
      improvement: [],
      modification: [],
    },
    work_order_user_assignees: {},
    work_order_vendor_assignees: {},
  },
  completedSteps: {},
  loading: true,
  notFound: false,
  success: false,
};

const buildInitialConfigData = data => {
  //let configData = {};

  const presets = {
    high: ['hög'],
    medium: ['medel', 'mellan'],
    low: ['låg'],
  };

  const work_order_priorities = {
    high: data.work_order_priorities
      .filter(prio => presets.high.includes(prio[data.amu_language].toLowerCase()))
      .map(prio => prio.id),
    medium: data.work_order_priorities
      .filter(prio => presets.medium.includes(prio[data.amu_language].toLowerCase()))
      .map(prio => prio.id),
    low: data.work_order_priorities
      .filter(prio => presets.low.includes(prio[data.amu_language].toLowerCase()))
      .map(prio => prio.id),
  };

  const request_priorities = {
    high: data.request_priorities
      .filter(prio => presets.high.includes(prio[data.amu_language].toLowerCase()))
      .map(prio => prio.id),
    medium: data.request_priorities
      .filter(prio => presets.medium.includes(prio[data.amu_language].toLowerCase()))
      .map(prio => prio.id),
    low: data.request_priorities
      .filter(prio => presets.low.includes(prio[data.amu_language].toLowerCase()))
      .map(prio => prio.id),
  };

  return {
    admin_id: null,
    admin_override_email: '',
    lang: data.amu_language,
    new_lang: 'sv',
    timezone: 'Europe/Stockholm',
    work_order_priorities: work_order_priorities,
    work_order_user_assignees: {},
    work_order_vendor_assignees: {},
    request_priorities: request_priorities,
    company_mode: 'all',
    spare_part_location_field: 'placering',
    work_order_categories: {
      preventive: [],
      preventive_condition_based: [],
      preventive_predetermined: [],
      corrective: [],
      corrective_defered: [],
      corrective_immediate: [],
      improvement: [],
      modification: [],
    },
    ignore_work_orders_for_inactive_asset: false,
    vendor_categories: [],
  };
};

const validateConfigData = (amuData, configData) => {
  let failedSteps = [];
  let valid = true;

  const admin_user = amuData.users.find(user => user.id === configData.admin_id);
  if (admin_user && [null, ''].includes(admin_user.email) && configData.admin_override_email === '') {
    failedSteps = [...failedSteps, 2];
    valid = false;
  }

  return valid;
};

const buildInitialCompletedSteps = () => {
  let completedSteps = {};

  for (var x = 0; x < MigrationHelper.steps.length + 1; x++) {
    completedSteps[x] = false;
  }

  return completedSteps;
};

// ------

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case types.FETCH_IMPORT_DATA_SUCCESS:
      return {
        ...state,
        amuData: action.payload.data,
        loading: false,
        configData: buildInitialConfigData(action.payload.data),
        completedSteps: buildInitialCompletedSteps(),
      };

    case types.FETCH_IMPORT_DATA_FAILED:
      return update(state, {
        notFound: { $set: true },
        loading: { $set: false },
      });

    case types.UPDATE_CONFIG:
      return {
        ...state,
        configData: update(state.configData, action.payload),
      };

    case types.SUBMIT_SUCCESS:
      return update(state, {
        success: { $set: true },
      });

    case types.COMPLETE_STEP:
      return update(state, {
        completedSteps: {
          [action.payload]: {
            $set: true,
          },
        },
      });

    default:
      return state;
  }
};
