import React, { Component } from "react";
import styles from "./style.module.scss";

export default class Header extends Component {
  render() {
    return (
      <div className={styles["header"]}>
        <div className={styles["title"]}>{this.props.title}</div>
        <div className={styles["subtitle"]}>{this.props.subtitle}</div>
      </div>
    );
  }
}
