import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Button } from 'views/components/General';
import { CSSTransition } from 'react-transition-group';
import PerfectScrollbar from 'react-perfect-scrollbar';
import styles from './style.module.scss';
import { FormattedMessage } from 'react-intl';
import Row from './Row';
import Separator from './Separator';

class MainContentAdvancedFilter extends Component {
  constructor(props) {
    super(props);
    this.filterContainerRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  handleKeyDown = e => {
    if (!this.props.open) return;
    if (e.key === 'Escape' || e.key === 'Esc') {
      this.props.onClose();
    }
  };

  render() {
    return ReactDOM.createPortal(
      <>
        <CSSTransition
          unmountOnExit
          in={this.props.open}
          timeout={150}
          classNames={{
            enter: styles['overlay-container-enter'],
            enterActive: styles['overlay-container-enter-active'],
            exit: styles['overlay-container-exit'],
            exitActive: styles['overlay-container-exit-active'],
          }}
        >
          <div className={styles['overlay-container']}>
            <div className={styles['overlay']} onClick={this.props.onClose} />
          </div>
        </CSSTransition>
        <div
          className={`${styles['filter-container']} ${this.props.open ? styles['filter-is-showing'] : ''}`}
          ref={this.filterContainerRef}
        >
          <div className={styles['content-container']}>
            <PerfectScrollbar>
              <div className={styles['content']}>
                <div className={styles['header']}>
                  <p>
                    <FormattedMessage id="screens.work-orders.filters.advanced.title" />
                  </p>
                </div>
                {this.props.children}
              </div>
            </PerfectScrollbar>
          </div>

          <div className={styles['actions']}>
            <Button.Group>
              <Button
                primary
                label="general.search"
                onClick={this.props.onClose}
                loading={this.props.loading}
              />
              <Button
                disabled={this.props.amountOfAppliedFilters == 0}
                label={
                  <div className={styles['number-container']}>
                    <FormattedMessage id="general.clean" />
                    {this.props.amountOfAppliedFilters == 0 ? null : (
                      <div className={styles['number']}>{this.props.amountOfAppliedFilters}</div>
                    )}
                  </div>
                }
                translate={false}
                onClick={this.props.onClear}
              />
            </Button.Group>
          </div>
        </div>
      </>,
      document.body
    );
  }
}

MainContentAdvancedFilter.Row = Row;
MainContentAdvancedFilter.Separator = Separator;
export default MainContentAdvancedFilter;
