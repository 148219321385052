import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import Header from '../components/Header';
import Content from '../components/Content';
import { Button, Field } from 'views/components/General';
import { MigrationSelectors, MigrationOperations } from 'state/ducks/migration';
import styles from './style.module.scss';

class Timezone extends Component {
  state = {
    timezone: this.props.configData.timezone,
  };

  renderSelectedLang = () => {
    switch (this.props.configData.new_lang) {
      case 'sv':
        return 'Svenska';
      case 'en':
        return 'Engelska';
      default:
        return '';
    }
  };

  renderField = () => (
    <div>
      <Field.Text
        focus
        value={this.state.timezone}
        onChange={timezone => this.setState({ timezone })}
        onBlur={timezone => {
          this.props.updateConfig({
            timezone: {
              $set: timezone,
            },
          });
        }}
      />
      <p className={styles['link']}>
        <span>Giltiga tidszoner hittas </span>
        <a
          href="https://en.wikipedia.org/wiki/List_of_tz_database_time_zones"
          target="_blank"
          rel="noopener noreferrer"
        >
          här
        </a>
      </p>
    </div>
  );

  render() {
    return (
      <>
        <Header title="Tidszon" subtitle="Bestäm vilken tidszon ni vill använda i Mainter" />
        <Content width={420}>{this.renderField()}</Content>
        <Button
          primary
          label="Nästa steg"
          onClick={() => {
            if (this.props.configData.timezone === '') {
              alert('Måste fylla i en tidszon');
              return;
            }

            this.props.completeStep(this.props.step);
            this.props.history.push(`?step=${this.props.step + 1}`);
          }}
        />
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateConfig: MigrationOperations.updateConfig,
      completeStep: MigrationOperations.completeStep,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    configData: MigrationSelectors.configData(state),
    amuData: MigrationSelectors.amuData(state),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Timezone));
